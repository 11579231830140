<template>
  <div class="page-title">Trial Examination</div>
  <div class="exams">
    <div v-if="exams.length < 1">
      No data
    </div>
    <div v-else>
      <div v-for="exam in exams"
        class="exam"
        @click="selectExam(exam)"
        :class="{selected: isSelected(exam)}"
        :key="exam.id">
        Step {{exam.step}}<span class="ml-2">{{exam.title}}</span>
      </div>
    </div>
  </div>
  <button
    :disabled="!selectedExam"
    @click="submit"
    class="btn-bg center mt-2">{{label}}</button>
</template>

<script>
export default {
  name: 'SelectExam',
  props: {
    label: {
      type: String,
      require: true,
    },
  },
  computed: {
    isSelected() {
      return exam => exam.id === this.selectedExam?.id
    }
  },
  data() {
    return {
      exams: [],
      selectedExam: null,
    }
  },
  async created() {
    const step = this.$route.query.step
    const forms = this.$route.query.forms
    this.exams = (await this.$api.get(`/tests?type=trial,trial_reading,trial_listening&step=${step}&forms=${forms}`)).data.tests
  },
  methods: {
    selectExam(exam) {
      this.selectedExam = exam
    },
    submit() {
      this.$emit('submit', this.selectedExam)
    },
  }
}
</script>

<style lang="scss" scoped>
.exams {
  background: white;
  border-radius: 5px;
  width: 65%;
  margin: auto;
  margin-top: 20px;
  min-height: 60vh;
  box-shadow: 3px 6px 6px 1px rgb(80 80 80 / 20%);
  .exam {
    border-bottom: 1px solid rgb(241, 216, 216);
    padding: 10px 5px;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
    padding-left: 20px;
  }
  .exam:hover, .selected {
    background: rgb(243, 233, 233);
    cursor: pointer;
  }
}
</style>

