<template>
  <div class="header">
    <div
      @click="backToMenu"
      class="btn-img">
      <img src="@/assets/icon-menu.png"/>
    </div>
  </div>
  <select-trial-exam
    label="Start"
    @submit="startExam"/>
</template>

<script>
import SelectTrialExam from '../../components/molecule/SelectTrialExam.vue'

export default {
  name: 'Exams',
  components: { SelectTrialExam, },
  props: {
  },
  data() {
    return {
    }
  },
  methods: {
    startExam(exam) {
      this.$store.commit('selectTest', exam)
      this.$router.push(`/lesson/${exam.id}/exam`)
    },
    backToMenu() {
      this.$router.push(`/menu`)
    },
  }
}
</script>

<style lang="scss" scoped>
</style>

